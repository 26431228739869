<script setup lang="ts">
// types
import type {
  ValidationOptions,
  FormValidationResult,
  GenericObject
} from 'vee-validate'

// utilities
import { dateWithOffset } from '@revolutionprep/utils'
import { format } from 'date-fns'

// state
import { useConsultStore } from '@/store/consult'

/**
 * props
 * ==================================================================
 */
interface Props {
  validationErrors?: Partial<Record<string, string | undefined>>
  formValidationFunction:
    (opts?: Partial<ValidationOptions> | undefined) => Promise<FormValidationResult<GenericObject, GenericObject>>
}
const props = withDefaults(defineProps<Props>(), {
  validationErrors: undefined
})

/**
 * state
 * ==================================================================
 */
const editConsult = ref(true)

/**
 * stores
 * ==================================================================
 */
const consultStore = useConsultStore()
const { consult } = storeToRefs(consultStore)

/**
 * computed
 * ==================================================================
 */
const imminentCloseDetails = computed(() => {
  const imminentClose = consult.value?.imminentClose
  const revenue = imminentClose?.revenue
  const sentAt = imminentClose?.sentAt
  if (imminentClose && revenue && sentAt) {
    return `($${Number(revenue)} on ${
      format(dateWithOffset(sentAt), 'M/dd/yyyy')
    })`
  } else {
    return null
  }
})

const imminentCloseLabel = computed(() => {
  return imminentCloseValue.value ? 'Yes' : 'No'
})

const imminentCloseValue = computed(() => {
  return Boolean(consult.value?.imminentClose)
})

/**
 * methods
 * ==================================================================
 */
function toggleEditConsult (value: boolean) {
  editConsult.value = value
}

/**
 * watchers
 * ==================================================================
 */
watch(consult, () => {
  editConsult.value = !consult.value?.completed
})

/**
 * setup
 * ==================================================================
 */
if (consult.value) {
  editConsult.value = !consult.value.completed
}
</script>

<template>
  <lazy-r-card-edit-profile
    class="mb-6"
    :edit="editConsult"
    card-name="Recommendation"
    card-icon="mdi-sticker-check-outline"
    @toggle-edit="toggleEditConsult($event)"
  >
    <template #card-form>
      <FormEditConsult
        v-if="consult"
        :consult-data="consult"
        :form-validation-function="props.formValidationFunction"
        :validation-errors="props.validationErrors"
        @toggle-form="toggleEditConsult($event)"
      />
    </template>
    <template #card-data>
      <dl class="v-row">
        <dt
          v-if="consult?.outcome"
          class="v-col-sm-6 v-col-md-4 v-col-xl-3 font-weight-bold"
        >
          Outcome:
        </dt>
        <dd
          v-if="consult?.outcome"
          class="v-col-sm-6 v-col-md-8 v-col-xl-9"
        >
          {{ consult?.outcome }}
        </dd>
        <dt
          v-if="consult?.outcome === 'No Recommendation' && consult?.noPitchReason"
          class="v-col-sm-6 v-col-md-4 v-col-xl-3 font-weight-bold"
        >
          Reason:
        </dt>
        <dd
          v-if="consult?.outcome === 'No Recommendation' && consult?.noPitchReason"
          class="v-col-sm-6 v-col-md-8 v-col-xl-9"
        >
          {{ consult?.noPitchReason }}
        </dd>
        <dt
          v-if="consult?.outcome === 'Recommendation' && consult?.pitchedProduct"
          class="v-col-sm-6 v-col-md-4 v-col-xl-3 font-weight-bold"
        >
          Product:
        </dt>
        <dd
          v-if="consult?.outcome === 'Recommendation' && consult?.pitchedProduct"
          class="v-col-sm-6 v-col-md-8 v-col-xl-9"
        >
          {{ consult?.pitchedProduct }}
        </dd>
        <dt
          v-if="consult?.outcome === 'Recommendation'"
          class="v-col-sm-6 v-col-md-4 v-col-xl-3 font-weight-bold"
        >
          Imminent Close:
        </dt>
        <dd
          v-if="consult?.outcome === 'Recommendation'"
          class="v-col-sm-6 v-col-md-8 v-col-xl-9"
        >
          <div>{{ imminentCloseLabel }}</div>
          <div v-if="imminentCloseValue">
            {{ imminentCloseDetails }}
          </div>
        </dd>
        <dt
          v-if="consult?.notes"
          class="v-col-sm-6 v-col-md-4 v-col-xl-3 font-weight-bold"
        >
          Notes:
        </dt>
        <dd
          v-if="consult?.notes"
          class="v-col-sm-6 v-col-md-8 v-col-xl-9"
          :style="{
            'max-height': '400px',
            'overflow-y': 'auto'
          }"
        >
          {{ consult?.notes }}
        </dd>
      </dl>
    </template>
  </lazy-r-card-edit-profile>
</template>
