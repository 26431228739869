// types
import type {
  Consult,
  Nullable
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useConsultStore = defineStore('consult', () => {
  // fetch repositories
  const { $consults } = useNuxtApp()

  // state
  const consult = ref<Nullable<Consult>>(null)

  // actions
  async function show (
    id: number | string,
    config?: NitroFetchOptions<string>
  ) {
    const { consult: _consult } =
      await $consults.show<{ consult: Consult }>(id, config)
    consult.value = _consult
    return _consult
  }

  async function update (
    id: number | string,
    payload?: Record<string, any>,
    config?: NitroFetchOptions<string>
  ) {
    const { consult: _consult } =
      await $consults.update<{ consult: Consult }>(
        id,
        { consult: payload },
        config
      )
    consult.value = _consult
    return _consult
  }

  return {
    consult,
    show,
    update
  }
})
