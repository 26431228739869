// types
import type { Consult, Nullable } from '@revolutionprep/types'

export function useConsult () {
  // state
  const consult = ref<Nullable<Consult>>(null)
  const outcomes = ref([
    'One Call Close',
    'Recommendation',
    'No Recommendation'
  ])

  // methods
  function setConsultInitialState (consultData: Consult) {
    consult.value = Object.assign({}, consultData)
    if (
      consult.value.outcome &&
      !outcomes.value.includes(consult.value.outcome)
    ) {
      consult.value.outcome = null
    }
  }

  return {
    consult,
    outcomes,
    setConsultInitialState
  }
}
